<template>
    <div>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col lg="3">
              <label>From Date</label>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dots_startdate_from"
                    outlined
                    dense
                    readonly
                    persistent-hint
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dots_startdate_from"
                  no-title
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col lg="3">
              <label>To Date</label>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dots_startdate_to"
                    outlined
                    dense
                    readonly
                    persistent-hint
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dots_startdate_to"
                  no-title
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col lg="3">
              <label>App Use</label>
              <v-select
                v-model="dots_app_status"
                outlined
                dense
                item-text="text"
              item-value="value"
                :items="[
                { value: 1, text: `No` },
                { value: 2, text: `Yes` },
                { value: 3, text: '' },
              ]"
              ></v-select>
            </v-col>
            <v-col lg="3">
                    <v-spacer></v-spacer><br>
                    <v-btn class="form-btn" @click="exportData">
                        <v-icon left>mdi-chevron-right-circle-outline</v-icon>Export
                    </v-btn>
            </v-col>
           
          </v-row>
        </v-card-text>
        
      </v-card>
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";
  import moment from "moment";
  
  export default {
    components: {},
    data: () => ({
      dots_startdate_from: null,
      dots_startdate_to:null,
      dots_app_status: 1,
    }),
    computed: {
      ...mapState("Volunteer", ["volunteers"]),
      ...mapState("Admin", ["admin"]),
    },
    methods: {
      exportData() {
        let data = {
         township_id: this.admin.township_id,
          dots_app_status: this.dots_app_status,
          
        };
        if (this.dots_startdate_from && this.dots_startdate_to) {
          data.dots_startdate_from=moment(this.dots_startdate_from).format("YYYY-MM-DD");
          data.dots_startdate_to=moment(this.dots_startdate_to).format("YYYY-MM-DD");
          
          if( data.dots_startdate_from> data.dots_startdate_to)
          {
             data.dots_startdate_from=null;
             data.dots_startdate_to=null;
             alert('Cannot be greater than From Date');
             return false;
          }
        }else
        {
            data.dots_startdate_from=null;
            data.dots_startdate_to=null;
             alert('Please select From and To date');
             return false;
        }
        
        this.$store.dispatch("Presumptive/dotsgetExport", data);
      },
    },
    created() {
      this.$store.dispatch("Volunteer/getVolunteers");
    },
  };
  </script>
  
  <style scoped></style>
  